<script setup lang="ts">
// COMPOSABLES
const { tt } = useTypedI18n();
const { locale } = useI18n();

// DATA
const image = computed<string>(() => `/hp/vision_${locale.value}.png`);
</script>

<template>
    <BlockCtaImageText
        :caption="tt('homepage.vision.caption')"
        :title="tt('homepage.vision.title')"
        :highlight="tt('homepage.vision.highlight')"
        :image="image"
    />
</template>
